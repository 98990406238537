
// Fonts
//@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";
@mixin box-shadow($value) {
  -webkit-box-shadow: $value;
  -moz-box-shadow: $value;
  box-shadow: $value;
}

.text-left-color{color:$left-color !important;}
.text-right-color{color:$right-color !important;}


.border-left-color{border-color:$left-color !important;}
.border-right-color{border-color:$right-color !important;}

.bordered-shadowed{
  border-radius: 4px;
  @include box-shadow(0 0 15px rgba(0, 0, 0, 0.2));

}
.no-margin{ margin:0px !important;}

// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
